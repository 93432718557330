<template>
    <div>
        <v-row>
            <v-col cols='1'>
                <v-btn outlined icon x-large @click="showPayment()">
                    <v-icon> {{ icons.mdiChevronLeft }}</v-icon>
                </v-btn>
            </v-col>
            <v-col>
                <span class="title">
                    <h2>Separar Pagamento</h2>
                </span>
            </v-col>
            <!-- <v-col cols="1">
            <v-btn outlined icon color="primary" x-large>
                <v-icon> {{ icons.mdiCog }}</v-icon>
            </v-btn>
        </v-col> -->
        </v-row>
        <!-- EMPRESA  -->
        <v-row v-if="getCompany">
            <v-col>
                <v-card outlined :class="{ card_selected: getSelectecResposible.origin == 'company' }"
                    @click="setResponsibleCompany(getCompany)">
                    <v-card-title>
                        {{ getCompany ? getCompany.fantasy_name : '' }}
                    </v-card-title>
                    <v-card-text>
                        <v-row v-for="(item, index) in itemsSelectedCompany " :key="index">
                            <v-col class=""></b>
                                {{ item.description }}
                                </b>
                            </v-col>
                            <v-col cols="3" class="text-right">
                                {{ formatMoney(item.sale_price*item.pivot.quantity) }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="text">Impostos</v-col>
                            <v-col class=" text text-right">{{ formatMoney(sumISSCompany) }}</v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-card v-for="payment in getpaymentMethodsSeparate" :key="payment.method" class="mb-2">
                                    <v-card-text v-if="payment.method == 'money' && payment.origin == 'company'">
                                        <v-row>
                                            <v-col>
                                                <v-icon color="success" large> {{ icons.mdiCashUsd }}</v-icon>
                                                <span class="text mt-4"> Dinheiro</span>
                                            </v-col>
                                            <v-col class="text-right">
                                                <span class="text mt-4"> R$ {{ formatMoney(payment.value) }}</span>
                                                <v-btn class="ml-3" @click="removePayment(payment)" icon>
                                                    <v-icon color="secondary"> {{ icons.mdiTrashCanOutline }}</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>

                                    <v-card-text v-if="payment.method == 'pix' && payment.origin == 'company'"
                                        class="mb-2">
                                        <v-row>
                                            <div class="log_pix mt-2 ml-3">
                                                <img width="40" src="@/assets/images/logos/logo-pix.png" alt="">
                                            </div>
                                            <div class="text_method_pix mt-4 ml-1" style="font-size:large">
                                                <span>Pix</span>
                                            </div>
                                            <v-col class="text-right">

                                                <span class="text "> R$ {{ formatMoney(payment.value) }}</span>
                                                <v-btn class="ml-3" @click="removePayment(payment)" icon>
                                                    <v-icon color="secondary"> {{ icons.mdiTrashCanOutline }}</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>

                                    <v-card-text v-if="payment.method == 'credit_card' && payment.origin == 'company'"
                                        class="mb-2">
                                        <v-row>
                                            <v-col>
                                                <v-icon color="primary" large> {{ icons.mdiCreditCard }}</v-icon>
                                                <span class="text mt-4"> Cartão de Crédito</span>
                                            </v-col>
                                            <v-col class="text-right">
                                                <span class="text mt-4"> R$ {{ formatMoney(payment.value) }}</span>
                                                <v-btn class="ml-3" @click="removePayment(payment)" icon>
                                                    <v-icon color="secondary"> {{ icons.mdiTrashCanOutline }}</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>

                                    <v-card-text v-if="payment.method == 'debit_card' && payment.origin == 'company'"
                                        class="mb-2">
                                        <v-row>
                                            <v-col>
                                                <v-icon color="success" large> {{ icons.mdiCreditCard }}</v-icon>
                                                <span class="text mt-4"> Cartão de Débito</span>
                                            </v-col>
                                            <v-col class="text-right">
                                                <span class="text mt-4"> R$ {{ formatMoney(payment.value) }}</span>
                                                <v-btn class="ml-3" @click="removePayment(payment)" icon>
                                                    <v-icon color="secondary"> {{ icons.mdiTrashCanOutline }}</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>

                                    <v-card-text v-if="payment.method == 'invoice' && payment.origin == 'company'"
                                        class="mb-2">
                                        <v-row>
                                            <v-col>
                                                <v-icon color="primary" large> {{ icons.mdiCalendarClock }}</v-icon>
                                                <span class="text mt-4"> Faturado</span>
                                            </v-col>
                                            <v-col class="text-right">
                                                <span class="text mt-4"> R$ {{ formatMoney(payment.value) }}</span>
                                                <v-btn class="ml-3" @click="removePayment(payment)" icon>
                                                    <v-icon color="secondary"> {{ icons.mdiTrashCanOutline }}</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>


                                    <v-card-text v-if="payment.method == 'other' && payment.origin == 'company'"
                                        class="mb-2">
                                        <v-row>
                                            <v-col>
                                                <v-icon color="error" large> {{ icons.mdiCashMultiple }}</v-icon>
                                                <span class="text mt-4"> Outros</span>
                                            </v-col>
                                            <v-col class="text-right">

                                                <span class="text "> R$ {{ formatMoney(payment.value) }}</span>
                                                <v-btn class="ml-3" @click="removePayment(payment)" icon>
                                                    <v-icon color="secondary"> {{ icons.mdiTrashCanOutline }}</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>

                                </v-card>
                            </v-col>
                        </v-row>

                    </v-card-text>
                    <v-card-actions>

                        <v-row v-if="sumItemsSelectedCompany > 0">
                            <v-col class="text-center mt-6">
                                <v-btn @click="dialog = true" elevation="0" class="btn-text" x-large color="primary">
                                    <v-icon>{{ icons.mdiPlusCircleOutline }}</v-icon> &nbsp Adicionar Forma de pagamento -
                                    &nbsp <b> R$ {{ formatMoney(sumItemsSelectedCompany) }} a pagar</b>
                                </v-btn>
                            </v-col>
                        </v-row>

                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <v-row v-for="(guest, index) in getGuests" :key="index">
            <v-col>
                <v-card outlined
                    :class="{ card_selected: (getSelectecResposible.origin == 'guest' && getSelectecResposible.id == guest.id) }"
                    @click="setResponsibleGuest(guest)">
                    <v-card-title>
                        {{ guest.name }}
                    </v-card-title>
                    <v-card-text>
                        <v-row v-for="(item, index) in itemsSelectedGuests(guest.id) " :key="index">
                            <v-col>
                                {{ item.description }}
                            </v-col>
                            <v-col class="text-right">
                                {{ formatMoney(item.sale_price*item.pivot.quantity) }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="text">Impostos</v-col>
                            <v-col class=" text text-right">{{ formatMoney(sumISSPerGuests(guest.id)) }}</v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-card v-for="payment in getpaymentMethodsSeparate" :key="payment.method">
                                    <v-card-text
                                        v-if="payment.method == 'money' && payment.origin == 'guest' && payment.id == guest.id">
                                        <v-row>
                                            <v-col>
                                                <v-icon color="success" large> {{ icons.mdiCashUsd }}</v-icon>
                                                <span class="text mt-4"> Dinheiro</span>
                                            </v-col>
                                            <v-col class="text-right">
                                                <span class="text mt-4"> R$ {{ formatMoney(payment.value) }}</span>
                                                <v-btn class="ml-3" @click="removePayment(payment)" icon>
                                                    <v-icon color="secondary"> {{ icons.mdiTrashCanOutline }}</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>

                                    <v-card-text
                                        v-if="payment.method == 'pix' && payment.origin == 'guest' && payment.id == guest.id">
                                        <v-row>
                                            <div class="log_pix mt-2 ml-3">
                                                <img width="40" src="@/assets/images/logos/logo-pix.png" alt="">
                                            </div>
                                            <div class="text_method_pix mt-4 ml-1" style="font-size:large">
                                                <span>Pix</span>
                                            </div>
                                            <v-col class="text-right">
                                                <span class="text "> R$ {{ formatMoney(payment.value) }}</span>
                                                <v-btn class="ml-3" @click="removePayment(payment)" icon>
                                                    <v-icon color="secondary"> {{ icons.mdiTrashCanOutline }}</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                    <v-card-text
                                        v-if="payment.method == 'credit_card' && payment.origin == 'guest' && payment.id == guest.id"
                                        class="mb-2">
                                        <v-row>
                                            <v-col>
                                                <v-icon color="primary" large> {{ icons.mdiCreditCard }}</v-icon>
                                                <span class="text mt-4"> Cartão de Crédito</span>
                                            </v-col>
                                            <v-col class="text-right">
                                                <span class="text mt-4"> R$ {{ formatMoney(payment.value) }}</span>
                                                <v-btn class="ml-3" @click="removePayment(payment)" icon>
                                                    <v-icon color="secondary"> {{ icons.mdiTrashCanOutline }}</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>

                                    <v-card-text
                                        v-if="payment.method == 'debit_card' && payment.origin == 'guest' && payment.id == guest.id"
                                        class="mb-2">
                                        <v-row>
                                            <v-col>
                                                <v-icon color="success" large> {{ icons.mdiCreditCard }}</v-icon>
                                                <span class="text mt-4"> Cartão de Débito</span>
                                            </v-col>
                                            <v-col class="text-right">
                                                <span class="text mt-4"> R$ {{ formatMoney(payment.value) }}</span>
                                                <v-btn class="ml-3" @click="removePayment(payment)" icon>
                                                    <v-icon color="secondary"> {{ icons.mdiTrashCanOutline }}</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                    <v-card-text
                                        v-if="payment.method == 'invoice' && payment.origin == 'guest' && payment.id == guest.id"
                                        class="mb-2">
                                        <v-row>
                                            <v-col>
                                                <v-icon color="primary" large> {{ icons.mdiCalendarClock }}</v-icon>
                                                <span class="text mt-4"> Faturado</span>
                                            </v-col>
                                            <v-col class="text-right">
                                                <span class="text mt-4"> R$ {{ formatMoney(payment.value) }}</span>
                                                <v-btn class="ml-3" @click="removePayment(payment)" icon>
                                                    <v-icon color="secondary"> {{ icons.mdiTrashCanOutline }}</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                    <v-card-text
                                        v-if="payment.method == 'other' && payment.origin == 'guest' && payment.id == guest.id">
                                        <v-row>
                                            <v-col>
                                                <v-icon color="error" large> {{ icons.mdiCashMultiple }}</v-icon>
                                                <span class="text mt-4"> Outros</span>
                                            </v-col>
                                            <v-col class="text-right">

                                                <span class="text "> R$ {{ formatMoney(payment.value) }}</span>
                                                <v-btn class="ml-3" @click="removePayment(payment)" icon>
                                                    <v-icon color="secondary"> {{ icons.mdiTrashCanOutline }}</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>

                                </v-card>
                            </v-col>
                        </v-row>

                    </v-card-text>
                    <v-card-actions>

                        <v-row v-if="sumItemsSelectedGuests(guest.id) > 0">
                            <v-col class="text-center mt-6">
                                <v-btn @click="dialog = true" elevation="0" class="btn-text" x-large color="primary">
                                    <v-icon>{{ icons.mdiPlusCircleOutline }}</v-icon> &nbsp Adicionar Forma de pagamento -
                                    &nbsp <b> R$ {{ formatMoney(sumItemsSelectedGuests(guest.id)) }} a pagar</b>
                                </v-btn>
                            </v-col>
                        </v-row>

                    </v-card-actions>
                </v-card>
            </v-col>

        </v-row>

        <!-- MODAL ESCOLHER TIPO DE PAGAMENTO -->
        <v-dialog v-model="dialog" max-width="478px">
            <v-card>

                <BarTitleDialog :margin_bottom="false" title="Escolher pagamento" @close="dialog = false">
                </BarTitleDialog>
                <v-row no-gutters>
                    <v-col>
                        <v-card class="btn_payment_methods" tile block elevation="0" x-large
                            @click="showModalPaymentMoney()">
                            <v-row>
                                <v-col>
                                    <v-icon color="success" x-large> {{ icons.mdiCashUsd }}</v-icon><span
                                        class="text_methods">Dinheiro</span>
                                </v-col>
                                <v-col class="text-right pt-4">
                                    <v-icon> {{ icons.mdiChevronRight }}</v-icon>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <v-card class="btn_payment_methods" tile block elevation="0" x-large @click="showModalPix()">
                            <v-row>
                                <v-col>
                                    <div class="log_pix">
                                        <img width="40" src="@/assets/images/logos/logo-pix.png" alt="">
                                    </div>
                                    <div class="text_method_pix">
                                        <span>Pix</span>
                                    </div>
                                </v-col>
                                <v-col class="text-right pt-4">
                                    <v-icon> {{ icons.mdiChevronRight }}</v-icon>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row no-gutters>
                    <v-col>
                        <v-card class="btn_payment_methods" tile block elevation="0" x-large
                            @click="showModalCreditCard()">
                            <v-row>
                                <v-col>
                                    <v-icon color="primary" x-large> {{ icons.mdiCreditCard }}</v-icon><span
                                        class="text_methods">Cartão de Crédito</span>
                                </v-col>
                                <v-col class="text-right pt-4">
                                    <v-icon> {{ icons.mdiChevronRight }}</v-icon>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <v-card class="btn_payment_methods" tile block elevation="0" x-large
                            @click="showModalDebitCard()">
                            <v-row>
                                <v-col>
                                    <v-icon color="success" x-large> {{ icons.mdiCreditCard }}</v-icon><span
                                        class="text_methods">Cartão de Débito</span>
                                </v-col>
                                <v-col class="text-right pt-4">
                                    <v-icon> {{ icons.mdiChevronRight }}</v-icon>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row no-gutters>
                    <v-col>
                        <v-card class="btn_payment_methods" tile block elevation="0" x-large
                            @click="showModalInvoice()">
                            <v-row>
                                <v-col>
                                    <v-icon color="primary" x-large> {{ icons.mdiCalendarClock }}</v-icon><span
                                        class="text_methods">Faturamento</span>
                                </v-col>
                                <v-col class="text-right pt-4">
                                    <v-icon> {{ icons.mdiChevronRight }}</v-icon>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
                <v-row no-gutters>
                    <v-col>
                        <v-card class="btn_payment_methods" tile block elevation="0" x-large @click="showModalOther()">
                            <v-row>
                                <v-col>
                                    <v-icon color="warning" x-large> {{ icons.mdiCurrencyUsd }}</v-icon><span
                                        class="text_methods">Outros</span>
                                </v-col>
                                <v-col class="text-right pt-4">
                                    <v-icon> {{ icons.mdiChevronRight }}</v-icon>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <!-- END MODAL ESCOLHER TIPO DE PAGAMENTO -->

        <!-- MODAL PAGAMENTO OUTROS  -->
        <v-dialog v-model="dailogOther" max-width="500px">
            <v-card>
                <v-row no-gutters>
                    <v-col class="bar_top"></v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="2"></v-col>
                    <v-col class="text-center text-h6">Escolher tipo de pagamento</v-col>
                    <v-col cols="2" class="text-right">
                        <v-btn icon @click="dailogOther = false">
                            <v-icon> {{ icons.mdiWindowClose }} </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text>
                    <v-row>
                        <v-col class="text-left text">
                            Valor a pagar
                        </v-col>
                        <v-col class="text-right text">
                            R$ {{ formatMoney(remainingValue) }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <vuetify-money :error-messages="validPayValueOther" v-model="payValueOther" outlined
                                label="Valor pago pelo cliente" />
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col class="change">{{ calcChange }}</v-col>
                    </v-row>
                    <v-row>
                        <v-col class="text-right">
                            <v-btn text large @click="dailogOther = false">Cancelar</v-btn>
                            <v-btn @click="addPayment()" class="ml-2" color="primary" large
                                :disabled="validPayValueOther">Receber dinheiro</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- END MODAL ESCOLHER VALOR FORMA OUTROS  -->

        <!-- MODAL PAGAMENTO DINHEIRO -->
        <ModalPaymentMoneySeparate v-model="modalPaymentMoney"></ModalPaymentMoneySeparate>
        <!-- END MODAL PAGAMENTO DINHEIRO -->

        <!-- MODAL PAGAMENTO PIX -->
        <ModalPaymentPixSeparate v-model="modalPaymentPix"></ModalPaymentPixSeparate>

        <!-- MODAL PAGAMENTO FATURADO -->
        <ModalPaymentInvoiceSeparate v-model="dailogInvoice"></ModalPaymentInvoiceSeparate>
        <!-- END MODAL PAGAMENTO FATURADO -->


        <!-- MODAL PAGAMENTO DEBITO -->
        <ModalPaymentDebitCardSeparate v-model="modalPaymentDebitCardSeparate" />
        <!-- END MODAL PAGAMENTO DEBITO -->


        <!-- MODAL PAGAMENTO CRDITO -->
        <ModalPaymentCreditCardSeparate v-model="modalPaymentCreditCardSeparate" />
        <!-- END MODAL PAGAMENTO CREDITO -->



        <!-- MSG SAIR DO PAGAMENTO SEPARADO -->
        <v-dialog v-model="dialogShowPayment" width="500">
            <v-card>
                <BarTitleDialog title="Deseja Sair ?" @close="dialogShowPayment = false" />
                <v-card-text>
                    <p class="text">Os dados do pagamento separado serão perdidos, deseja continuar?</p>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-row>
                        <v-col class="text-right">
                            <v-btn text large @click="dialogShowPayment = false">Não</v-btn>
                            <v-btn @click="confirmShowPayment()" x-large class="ml-2" color="primary" large>Sim,
                                Sair</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- END MSG SAIR DO PAGAMENTO SEPARADO -->


    </div>
</template>

<script>
import ModalPaymentMoneySeparate from './ModalPaymentMoneySeparate.vue';
import ModalPaymentCreditCardSeparate from './ModalPaymentCreditCardSeparate.vue';
import ModalPaymentDebitCardSeparate from './ModalPaymentDebitCardSeparate.vue';
import ModalPaymentInvoiceSeparate from './ModalPaymentInvoiceSeparate.vue';
import ModalPaymentPixSeparate from './ModalPaymentPixSeparate.vue';
import ModalPaymentSeparate from './ModalPaymentPixSeparate.vue';
import BarTitleDialog from '@/components/BarTitleDialog.vue';
import {
    mdiCog,
    mdiChevronLeft,
    mdiChevronRight,
    mdiCashUsd,
    mdiCreditCard,
    mdiCashMultiple,
    mdiCurrencyUsd,
    mdiPlusCircleOutline,
    mdiWindowClose,
    mdiTrashCanOutline,
    mdiCalendarClock
} from '@mdi/js'
import {
    mapGetters,
    mapMutations
} from 'vuex';

export default {
    components: {
        ModalPaymentMoneySeparate,
        BarTitleDialog,
        ModalPaymentPixSeparate,
        ModalPaymentInvoiceSeparate,
        ModalPaymentCreditCardSeparate,
        ModalPaymentDebitCardSeparate
    },
    data() {
        return {
            modalPaymentDebitCardSeparate: false,
            modalPaymentCreditCardSeparate: false,
            dialogShowPayment: false,
            dailogInvoice: false,
            tabs: null,
            dialog: false,
            dailogOther: false,
            payValueOther: 0,
            valueValid: [],
            modalPaymentMoney: false,
            modalPaymentPix: false,
            parcialBalance: 0,
            icons: {
                mdiPlusCircleOutline,
                mdiChevronLeft,
                mdiCog,
                mdiCashUsd,
                mdiCreditCard,
                mdiCashMultiple,
                mdiCurrencyUsd,
                mdiChevronRight,
                mdiWindowClose,
                mdiTrashCanOutline,
                mdiCalendarClock
            }
        }
    },
    watch: {
        dialog(val) {
            if (val) {

            }
        }
    },
    computed: {
        ...mapGetters('checkout', [
            'remainingValue',
            //  'getpaymentMethods',
            'getRoom',
            'getItemsCheckout',
            'getSumItemsSelectedCompany',
            'getSelectecResposible',
            'getpaymentMethodsSeparate',
            'getSubTotalSeparateWithTax',
            
        ]),

        calcChange() {
            let result = this.payValueOther - this.remainingValue

            if (Math.sign(result) == -1) { //se = o sinal do numero é negativo
                result = result * -1 //invert o sinal
            }

            //se o valor for maior
            if (this.payValueOther < this.remainingValue) {
                return 'Valor restante a ser pago : R$ ' + this.formatMoney(result)
            }
            //se o valor for igual
            if (this.payValueOther == this.remainingValue) {
                return 'Sem troco'
            }

            //se o valor for menor
            if (this.payValueOther > this.remainingValue) {
                return 'R$ ' + this.formatMoney(result) + ' de troco'
            }
        },
        validPayValueOther() {

            if (this.payValueOther <= 0) {
                return this.valueValid = ['Quantia deve ser 0.01 ou superior']
            }

            return null
        },
        getGuests() {
            return this.getRoom.current_accommodation.guest
        },
        getCompany() {
            return this.getRoom.current_accommodation.company
        },
        // retorna o total de items selecionado pela empresa
        sumItemsSelectedCompany() {
            const applyISS = this.getRoom.current_accommodation.with_iss

            let result = this.getItemsCheckout.reduce((acc, item) => {
                if (item.responsible) {

                    if (item.responsible.origin == 'company') {
                        let iss = 0
                        let iss_accommodation = 0
                        if (item.origin == 'service') {
                            iss = ((parseFloat(item.iss) / 100) * parseFloat(item.sale_price)) * parseFloat(item.pivot.quantity)
                        }
                        //iss de hospedagem
                        if (item.origin == 'accommodation' && applyISS == 'yes') {
                            iss_accommodation = 0.03 * parseFloat(item.sale_price)
                        }
                        return acc + parseFloat(item.sale_price)*parseFloat(item.pivot.quantity) + iss + iss_accommodation
                    } else {
                        return acc
                    }
                }
                return acc
            }, 0)

            return result
        },
        sumISSCompany() {
            const applyISS = this.getRoom.current_accommodation.with_iss
            if (applyISS == 'not') {
                return 0
            }
            let result = this.getItemsCheckout.reduce((acc, item) => {
                if (item.responsible) {

                    if (item.responsible.origin == 'company') {
                        let iss = 0
                        let iss_accommodation = 0
                        if (item.origin == 'service') {
                            iss = ((parseFloat(item.iss) / 100) * parseFloat(item.sale_price)) * parseFloat(item.pivot.quantity)
                        }

                        //iss de hospedagem
                        if (item.origin == 'accommodation') {
                            iss_accommodation = 0.03 * parseFloat(item.sale_price)
                        }
                        return acc + iss + iss_accommodation
                    } else {
                        return acc
                    }
                }
                return acc
            }, 0)

            return result
        },

        //lista de itens selecionado pela company
        itemsSelectedCompany() {
            let result = this.getItemsCheckout.filter((item) => {

                if (item.responsible) {
                    return item.responsible.origin == 'company'
                }

            })

            return result
        },

    },
    methods: {
        ...mapMutations('checkout', ['setStage',
            'addPaymentMethod',
            'removePaymentMethod',
            'setSelectecResposible',
            'removePaymentMethodSeparate',
            'clearCheckoutSeparete'
        ]),

        //lista de itens selecionado para cada hospede
        itemsSelectedGuests(guest_id) {
            let result = this.getItemsCheckout.filter((item) => {
                if (item.responsible) {
                    return item.responsible.origin == 'guest' && item.responsible.id == guest_id
                }
            })
            return result
        },

        // retorna o total de items selecionado guest
        sumItemsSelectedGuests(guest_id) {
            const applyISS = this.getRoom.current_accommodation.with_iss
            let result = this.getItemsCheckout.reduce((acc, item) => {
                if (item.responsible) {
                    if (item.responsible.origin == 'guest' && item.responsible.id == guest_id) {
                        let iss = 0
                        let iss_accommodation = 0
                        if (item.origin == 'service') {
                            iss = ((parseFloat(item.iss) / 100) * parseFloat(item.sale_price)) * parseFloat(item.pivot.quantity)
                        }
                        

                        //iss de hospedagem
                        if (item.origin == 'accommodation' && applyISS == 'yes') {
                            iss_accommodation = 0.03 * parseFloat(item.sale_price)
                        }

                        return acc + parseFloat(item.sale_price)*parseFloat(item.pivot.quantity) + iss + iss_accommodation
                    } else {
                        return acc
                    }
                }
                return acc
            }, 0)

            return result
        },
        sumISSPerGuests(guest_id) {

            const applyISS = this.getRoom.current_accommodation.with_iss
            if (applyISS == 'not') {
                return 0
            }

            let result = this.getItemsCheckout.reduce((acc, item) => {
                if (item.responsible) {
                    if (item.responsible.origin == 'guest' && item.responsible.id == guest_id) {
                        let iss = 0
                        let iss_accommodation = 0
                        if (item.origin == 'service') {
                            iss = ((parseFloat(item.iss) / 100) * parseFloat(item.sale_price)) * parseFloat(item.pivot.quantity)
                        }
                        //iss de hospedagem
                        if (item.origin == 'accommodation') {
                            iss_accommodation = 0.03 * parseFloat(item.sale_price)
                        }

                        return acc + iss + iss_accommodation
                    } else {
                        return acc
                    }
                }
                return acc
            }, 0)

            return result
        },
        //seleciona a company para atribuicao dos items de checkout
        setResponsibleCompany(company) {
            company.origin = 'company'
            this.setSelectecResposible(company)
        },

        setResponsibleGuest(guest) {
            guest.origin = 'guest'
            this.setSelectecResposible(guest)
        },
        showModalCreditCard() {
            this.dialog = false
            this.modalPaymentCreditCardSeparate = true;
        },
        showModalDebitCard() {
            this.dialog = false
            this.modalPaymentDebitCardSeparate = true;
        },
        showModalPaymentMoney() {
            this.dialog = false
            this.modalPaymentMoney = true;
        },
        showModalOther() {
            this.dialog = false
            this.dailogOther = true
        },
        showModalInvoice() {
            this.dialog = false
            this.dailogInvoice = true
        },
        showModalPix() {
            this.dialog = false
            this.modalPaymentPix = true
        },
        showGratuity() {
            this.setStage('gratuity');
        },
        showPayment() {
            this.dialogShowPayment = true
        },
        confirmShowPayment() {
            this.clearCheckoutSeparete()
            this.setStage('payment');
        },
        formatMoney(value) {
            return value.toLocaleString('PT-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })
        },
        //metodos dialog other
        addPayment() {
            const payment = {
                method: 'other',
                value: this.payValueOther
            }

            this.addPaymentMethod(payment)
            this.dailogOther = false
        },

        removePayment(payment) {
            this.removePaymentMethodSeparate(payment)
        }

    }
}
</script>

<style scoped>
.card_selected {
    border: solid #16b1ff 4px;

}

.log_pix {
    width: 40px;
    float: left;

}

.text_method_pix {
    color: black;
    margin-top: 8px;
}

.card {
    height: 100px;
    cursor: pointer;
}

.card:hover {
    background: rgb(248, 248, 248);
}

.text_card {
    color: black;
    font-size: 16px;
}

.title {
    color: black;
    font-size: 25px;
}

.btn_payment_methods {
    border-top: 1px solid #eeeeee;
    height: 81px;
    width: 478px;
    padding-top: 21px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
}

.btn_payment_methods:hover {
    background: rgb(248, 248, 248);
}

.span {
    margin-top: 10px;
}

.text_methods {
    color: black;
}

.text {
    color: black;
    font-size: large;
}

.change {
    color: blueviolet;
    font-size: medium;
}
</style>
