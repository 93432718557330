<template>
  <div>
    <v-data-table :loading="loadingTable" disable-pagination disable-sort hide-default-footer :headers="headers"
      :items="categories" sort-by="calories" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Previsão de Movimentação de Ocupação</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-menu :offset-y="true">
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn color="primary" outlined icon large v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                    <v-icon>
                      {{ icons.mdiTools }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Ferramentas</span>
              </v-tooltip>
            </template>
            <v-list>
              <v-list-item @click="sendExportExcel()">
                <v-list-item-title>
                  <v-icon>{{ icons.mdiFileExcelOutline }}</v-icon> Exportar Excel
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <a :href="newTab" target="_blank" v-bind="attrs" v-on="on">
                <v-btn class="ml-2" icon large outlined color="primary">
                  <v-icon>{{ icons.mdiOpenInNew }}</v-icon>
                </v-btn>
              </a>
            </template>
            <span>Nova Aba</span>
          </v-tooltip>
          <v-btn @click="dialogFilter = true" class="ml-2" color="primary" large outlined rounded><v-icon>{{
            icons.mdiFilter }}</v-icon>
            filtros</v-btn>
          <v-spacer></v-spacer>

        </v-toolbar>
      </template>

      <template v-slot:item.check_in="{ item }">
        {{ formatDate(item.check_in) }}
      </template>
      <template v-slot:item.check_out="{ item }">
        {{ formatDate(item.check_out) }}
      </template>
      <template v-slot:item.guests="{ item }">
        {{ item.guests[0].name }}
      </template>

      <template v-slot:no-data>
        <NoDataTable></NoDataTable>
      </template>
      <template v-slot:foot>
        <tr style="height:44px; background:#fafafa">
          <td colspan="5" class="text-right" style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px">
            TOTAL:</td>
          <td class="text-center" style="border-top:solid 1px #e4e3e6 ;padding-left:16px;padding-right:16px">
            <b>{{ countTotalGuest() }}</b>
          </td>
          <td class="text-center" style="border-top:solid 1px #e4e3e6 ;padding-left:16px;padding-right:16px">
            <b>{{ countTotalChildren() }}</b>
          </td>
        </tr>
      </template>



      <template v-slot:loading>
        Carregando...
      </template>
    </v-data-table>
    <v-dialog v-model="dialogFilter" width="700">
      <v-card>
        <BarTitleDialog title="FILTROS" @close="dialogFilter = false" />
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field :min="minDate" v-model="filter.date_start" outlined type="date" label="Data Ocupação"></v-text-field>
            </v-col>
          </v-row>
         </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row>
            <v-col class="text-left">
              <v-btn text color="error" x-large @click="clearFilter()">limpar filtros</v-btn>
            </v-col>

            <v-col class="text-right">
              <v-btn text color="secondary" x-large @click="dialogFilter = false">FECHAR</v-btn>
              <v-btn @click="confirmFilterr()" color="primary" class="ml-2" x-large>aplicar</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <MsgDelete v-model="dialogDelete" @delete-yes="deleteItemConfirm()" />

  </div>
</template>

<script>
import BarTitleDialog from '@/components/BarTitleDialog.vue'
import MsgDelete from '@/components/MsgDelete.vue'
import NoDataTable from '@/components/NoDataTable.vue'
import {
  mdiClose,
  mdiDeleteOutline,
  mdiFileExcelOutline,
  mdiFilter,
  mdiOpenInNew,
  mdiPencilOutline,
  mdiPlus,
  mdiTools
} from '@mdi/js'
import {
  mapActions
} from 'vuex'

export default {
  components: {
    MsgDelete,
    NoDataTable,
    BarTitleDialog
  },
  data: () => ({
    dialogFilter: false,
    categoryItems: [],
    loadingTable: false,
    totalStagesPaginate: 0,
    numberOfPagesPaginate: 0,
    currentPagePaginate: 0,
    dialogDelete: false,
    dialog: false,
    loading: false,
    minDate: '',
    filter: {
      occupation_date: '',
    },
    headers: [
    {
      text: 'HÓSPEDES',
      align: 'left',
      value: 'guests'
    },
    {
      text: 'QUARTO',
      value: 'description',
      align: 'center',
    },
    {
      text: 'TIPO',
      value: 'type'
    },
    {
      text: 'CHECKIN',
      value: 'check_in'
    },
    {
      text: 'CHECKOUT',
      value: 'check_out'
    },
    {
      text: 'QTD. HÓSPEDES',
      align: 'center',
      value: 'guests_count'
    },
    {
      text: 'QTD. CRIANÇAS',
      align: 'center',
      value: 'children_count'
    },
  
  
    ],
    categories: [],
    editedIndex: -1,
    editedItem: {
      description: '',
    },
    defaultItem: {
      description: '',
    },


    icons: {
      mdiPencilOutline,
      mdiDeleteOutline,
      mdiPlus,
      mdiClose,
      mdiOpenInNew,
      mdiTools,
      mdiFileExcelOutline,
      mdiFilter
    },
  }),

  computed: {

    newTab() {
      let url_atual = window.location.href;
      url_atual = url_atual.replace('#', '')
      return url_atual + '_blank'
    },


  },
  watch: {

  },

  created() {
    this.initialize()
    this.initialFilter()
    this.minDate = new Date().toISOString().split('T')[0]
  },

  methods: {
    ...mapActions('occupancy_movemment', ['getAll', 'exportExcel']),
    initialize() {
      this.loadingTable = true


      this.getAll(this.filter)
        .then(response => {
          this.categories = response.data
        })
        .finally(() => {
          this.loadingTable = false
        })
    },
    clearFilter() {
      this.filter.date_start= ''
    },

    confirmFilterr() {
      this.initialize()
      this.dialogFilter = false
    },

    qtdGuests(item) {
      if (item.current_accommodation) {
        return item.current_accommodation.guest.length
      }

      return 0
    },
    sendExportExcel() {
      this.exportExcel()
        .then((response) => {

          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'export.xlsx');
          document.body.appendChild(fileLink);
          fileLink.click();

        }).catch((response) => {
          console.log(response)
        }).finally(() => {

        })


    },
    countTotalGuest() {
      let sum = 0
      this.categories.forEach((item) => {
        if (item.guests_count) {
          sum += item.guests_count
        } else {
          sum += 0
        }

      })
      return sum
    },
    countTotalChildren() {
      let sum = 0
      this.categories.forEach((item) => {
        if (item.children_count) {
          sum += item.children_count
        } else {
          sum += 0
        }

      })
      return sum
    },
    countTotalRoom() {
      return this.categories.length
    },
    formatDate(date) {
      if (date) {
        const result = new Date(date);
        return result.toLocaleDateString('pt-BR', { timeZone: 'UTC' })
      }

    },
    initialFilter() {
      let date = new Date()
      this.filter.date_start = date.toISOString().split('T')[0]
    },


  },



}
</script>
