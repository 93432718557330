import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/Dashboard/Dashboard.vue'),
    meta: {
      layout: 'content',
    },
    // beforeEnter: (to, from,next) => {
    //   //return false
    //  // next()
    //   //return true
    // },
  },
  {
    path: '/reserve',
    name: 'reserve',
    component: () => import('@/views/Reserve/Reserve.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/completed_reserve',
    name: 'completed_reserve',
    component: () => import('@/views/Reserve/CompletedReserve.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/manager_room/:room_id?',
    name: 'manager_room',
    component: () => import('@/views/ManagerRoom/ManagerRoom.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/manager_room/checkout',
    name: 'checkout',
    component: () => import('@/views/ManagerRoom/_components/Checkout.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/manager_room/checkout_outstanding',
    name: 'checkout_outstanding',
    component: () => import('@/views/ManagerAccommodation/_components/Checkout.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/manager_room/checkout/payment',
    name: 'payment',
    component: () => import('@/views/ManagerRoom/_components/Payment.vue'),
    meta: {
      layout: 'content',
    },
  },
  
  //ADM ACCOMMMODATION
  {
    path: '/manager/checkouts',
    name: 'manager_checkouts',
    component: () => import('@/views/ManagerAccommodation/Checkouts.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/manager/outstanding',
    name: 'manager_outstanding',
    component: () => import('@/views/ManagerAccommodation/Outstanding.vue'),
    meta: {
      layout: 'content',
    },
  },
  //PDV
  {
    path: '/pdv',
    name: 'pdv',
    component: () => import('@/views/Pdv/Pdv.vue'),
    meta: {
      layout: 'content',
    },
  },

  //CADASTRO
  {
    path: '/guest',
    name: 'guest',
    component: () => import('@/views/Register/Guest/Guest.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/register/company',
    name: 'register/company',
    component: () => import('@/views/Register/Company/Company.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/register/supply',
    name: 'register/supply',
    component: () => import('@/views/Register/Supply/Supply.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/register/tariff',
    name: 'register/tariff',
    component: () => import('@/views/Register/Tariff/Tariff.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/register/room',
    name: 'register/room',
    component: () => import('@/views/Register/Room/Room.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/register/room_category',
    name: 'register/room_category',
    component: () => import('@/views/Register/RoomCategory/RoomCategory.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/register/pix',
    name: 'register/pix',
    component: () => import('@/views/Register/Pix/Pix.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/register/card',
    name: 'register/card',
    component: () => import('@/views/Register/Card/Card.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/register/product',
    name: 'register/product',
    component: () => import('@/views/Register/Product/Product'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/register/pdv',
    name: 'register/pdv',
    component: () => import('@/views/Register/Pdv/Pdv.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/register/service',
    name: 'register/service',
    component: () => import('@/views/Register/Service/Service'),
    meta: {
      layout: 'content',
    },
  },

  //CAIXA
  {
    path: '/cash_open',
    name: 'cash_open',
    component: () => import('@/views/Cash/CashOpen'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/cash',
    name: 'cash',
    component: () => import('@/views/Cash/Cash'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/cash_list',
    name: 'cash_list',
    component: () => import('@/views/Cash/CashList'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/cash_list/detalhes/:cash',
    name: 'cash_list_detalhes',
    component: () => import('@/views/Cash/_components/CashListDetails'),
    meta: {
      layout: 'content',
    },
  },
  //RELATÓRIO
  {
    path: '/report/advance_payment',
    name: 'report/advance_payment',
    component: () => import('@/views/Report/AdvancePayment/AdvancePayment.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/report/production_by_company',
    name: 'report/production_by_company',
    component: () => import('@/views/Report/ProductionByCompany/ProductionByCompany.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/report/production_by_company_details/:id/:company',
    name: 'report/production_by_company_details',
    component: () => import('@/views/Report/ProductionByCompany/_components/ProductionByCompanyDetails.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/occupied_room',
    name: 'occupied_room',
    component: () => import('@/views/Report/OccupiedRooms/OccupiedRooms.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/rds',
    name: 'rds',
    component: () => import('@/views/Report/RDS/Rds.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/occupation_map',
    name: 'occupation_map',
    component: () => import('@/views/Report/OccupationMap/OccupationMap.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/report/open_account',
    name: 'report/open_account',
    component: () => import('@/views/Report/OpenAccount/OpenAccount.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/occupied_room_blank',
    name: 'occupied_room_blank',
    component: () => import('@/views/Report/OccupiedRooms/OccupiedRooms.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/occupancy_movemment',
    name: 'occupancy_movemment',
    component: () => import('@/views/Report/OccupancyMovemment/OccupancyMovemment.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/intern_consumption',
    name: 'intern_consumption',
    component: () => import('@/views/Report/InternConsumption/InternConsumption.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/breakfast',
    name: 'breakfast',
    component: () => import('@/views/Report/Breakfast/Breakfast.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/breakfast_blank',
    name: 'breakfast_blank',
    component: () => import('@/views/Report/Breakfast/Breakfast.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/reserves_today',
    name: 'reserves_today',
    component: () => import('@/views/Report/ReservesToday/ReservesToday.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/report/product_sale',
    name: 'report/product_sale',
    component: () => import('@/views/Report/ProductSale/ProductSale.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/report/product_posted',
    name: 'report/product_posted',
    component: () => import('@/views/Report/ProductPosted/ProductPosted.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/reserves_today_blank',
    name: 'reserves_today_blank',
    component: () => import('@/views/Report/ReservesToday/ReservesToday.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/report/payment',
    name: 'payment_report',
    component: () => import('@/views/Report/Payment/Payment.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/report/payment',
    name: 'payment_report',
    component: () => import('@/views/Report/Payment/Payment.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/report/payment_checkout',
    name: 'payment_checkout',
    component: () => import('@/views/Report/Payment/PaymentCheckout.vue'),
    meta: {
      layout: 'content',
    },
  },

  //Debug
  {
    path: '/report/daily_posted',
    name: 'daily_posted',
    component: () => import('@/views/Report/DailyPosted/Daily.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/report/daily_posted_itens',
    name: 'daily_posted_itens',
    component: () => import('@/views/Report/DailyItensPosted/DailyItensPosted.vue'),
    meta: {
      layout: 'content',
    },
  },

  //SISTEMA
  //PERFIS
  {
    path: '/role/permission/:role_id',
    name: 'permission',
    component: () => import('@/views/System/Role/_components/Permission.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/role',
    name: 'role',
    component: () => import('@/views/System/Role/Role.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/user',
    name: 'user',
    component: () => import('@/views/System/User/User.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/config',
    name: 'config',
    component: () => import('@/views/System/Config/Config.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/audit',
    name: 'audit',
    component: () => import('@/views/System/Audit/Audit.vue'),
    meta: {
      layout: 'content',
    },
  },{
    path: '/diary_execution',
    name: 'diary_execution',
    component: () => import('@/views/System/Audit/DiaryExecution.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '*',
    component: () => import('@/views/Error404.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

// router.beforeEach((to, from) => {



//   //return true
// })

export default router
