<template>
  <div>
    <v-card>
      <v-card-actions>
        <v-row>
          <v-col class="text-right">
            <v-btn color="primary" x-large @click="save" :loading="loading" :disabled="loading">Salvar</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      <!-- <v-card-text> -->
      <!-- <v-row >
        <v-col>
          <v-text-field v-model="config.printer_name" outlined label="Nome da impressora"></v-text-field>
        </v-col>
        <v-col>
            <v-text-field v-model="config.ip_printer_cash" outlined label="IP Impressora caixa"></v-text-field>
        </v-col>
        <v-col>
            <v-text-field v-model="config.ip_printer_kitchen" outlined label="IP Impressora cozinha"></v-text-field>
        </v-col>
      </v-row> -->
      <!-- <v-row >
        <v-col cols="4">
          <v-select v-model="config.ip_printer_network" outlined label="Impressora rede" :items="[{text:'SIM',value:'yes'},{text:'NÃO',value:'not'}]"></v-select>
        </v-col>
      </v-row> -->
      <!-- </v-card-text> -->

    </v-card>
    <v-card class="mt-6">
      <v-card-title>
        Cadastro do Hotel
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field v-model="config.name" outlined label="Nome"> </v-text-field>
          </v-col>
          <v-col>
            <v-text-field v-mask="'(##)####-####'" v-model="config.phone" outlined label="Telefone"> </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field v-model="config.address" outlined label="Endereço"> </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field v-model="config.site" outlined label="Site"> </v-text-field>
          </v-col>
          <v-col>
            <v-text-field v-model="config.email" outlined label="Email"> </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-file-input outlined accept="image/*" label="Logo principal" @change="fileLogo"></v-file-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-file-input outlined accept="image/*" label="Logo relatórios " @change="fileLogoReport"></v-file-input>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mt-6">
      <v-card-title>
        Check-in e Check-out
      </v-card-title>
      <v-card-text>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field v-model="config.checkin" type="time" outlined label="Horário Check-in"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="config.checkout" type="time" outlined label="Horário Check-out"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card-text>
    </v-card>

    <v-card class="mt-6">
      <v-card-title>
        Alerta Diárias automáticas
      </v-card-title>
      <v-card-text>
        <v-card-text>
          <v-row>
            <v-col>
              <v-switch v-model="config.daily_alert_enabled" color="primary" inset label="Ativar"></v-switch>
            </v-col>
            <v-col>
              <v-text-field v-model="config.daily_alert_time" type="time" outlined :error='daily_alert_input.error'
                :error-messages='daily_alert_input.message' :disabled="!config.daily_alert_enabled"
                label="Horário"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="config.daily_alert_interval" type="number" outlined
                :error='daily_alert_interval_input.error' :error-messages='daily_alert_interval_input.message'
                :disabled="!config.daily_alert_enabled" label="Intervalo entre alertas (min)"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card-text>
    </v-card>

    <v-card class="mt-6">
      <v-card-title>
        Limite de consumo
      </v-card-title>
      <v-card-text>
        <v-card-text>
          <v-row>
            <v-col>
              <v-switch v-model="config.consumption_alert" color="primary" inset
                label="Ativar alerta de consumo"></v-switch>
            </v-col>
            <v-col>
              <vuetify-money :error='consumption_max_input.error' :error-messages='consumption_max_input.message'
                :disabled="!config.consumption_alert" v-model="config.consumption_max" outlined label="Cota máxima" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card-text>
    </v-card>

    <v-card class="mt-6">
      <v-card-title>
        API de envio de emails
      </v-card-title>
      <v-card-text>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field v-model="config.url_api" outlined label="URL API"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="config.api_key" outlined label="API KEY"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card-text>
    </v-card>

    <v-card class="mt-6">
      <v-card-title>
        API de envio via WhatsApp
      </v-card-title>
      <v-card-text>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field v-model="config.whats_endpoint" outlined label="URL API"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="config.whats_instance" outlined label="Instância"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field type="password" v-model="config.whats_key" outlined label="API KEY"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card-text>
    </v-card>

    <!-- SMTP 
    <v-card-title>
      Cadastro SMTP
   </v-card-title>
    <v-card-text>
      <v-card outlined>
        <v-card-text>
      <v-row>
        <v-col>
          <v-text-field v-model="smtp.mailer" outlined label="Mailer"></v-text-field>
        </v-col>
        <v-col>
          <v-text-field v-model="smtp.host" outlined label="Host"></v-text-field>
        </v-col>
        <v-col>
          <v-text-field v-model="smtp.port" outlined label="Port"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field v-model="smtp.username" outlined label="Username"></v-text-field>
        </v-col>
        <v-col>
          <v-text-field v-model="smtp.password" outlined label="Password"></v-text-field>
        </v-col>
        <v-col>
          <v-text-field v-model="smtp.encryption" outlined label="Encryption"></v-text-field>
        </v-col>
      </v-row>


      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-row>
          <v-col class="text-right">
            <v-btn color="primary" :loading="loading_smtp" :disabled="loading_smtp" @click="addSmtp()">Adicionar</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      </v-card>
    </v-card-text>

    <v-card-text>
      <v-card outlined :loading="loading_delete_smtp" :disabled="loading_delete_smtp">
       <v-data-table dense :headers="headers" :items="items_smtp">

        <template v-slot:item.actions="{ item }">
            <v-icon color="error"  @click="deleteSmtp(item)">
                {{ icons.mdiDeleteOutline }}
            </v-icon>
        </template>

</v-data-table>
</v-card>
</v-card-text>
-->

  </div>

</template>

<script>
import { mapActions } from 'vuex'

export default {
  data: () => ({
    file: null,
    file_report: null,
    consumption_max_input: {
      error: false,
      message: null
    },
    daily_alert_input: {
      error: false,
      message: null
    },
    daily_alert_interval_input: {
      error: false,
      message: null
    },
    config: {
      name: null,
      phone: null,
      address: null,
      site: null,
      email: null,
      checkin: null,
      checkout: null,
      url_api: null,
      api_key: null,
      consumption_alert: null,
      consumption_max: null,
      daily_alert_enabled: null,
      daily_alert_time: null,
      daily_alert_interval: null,
      whats_endpoint: null,
      whats_key: null,
      whats_instance: null
    },
    loading: false,
    /*
    loading_smtp: false,
    loading_delete_smtp: false,
    items_smtp: [],
    smtp: {
      mailer: null,
      host: null,
      port: null,
      username: null,
      password: null,
      encryption: null
    },
    smtp_default: {
      mailer: null,
      host: null,
      port: null,
      username: null,
      password: null,
      encryption: null
    },
    headers: [
      {
        text: 'MAILER',
        align: 'start',
        value: 'mailer',
      },
      {
        text: 'HOST',
        value: 'host',
      },
      {
        text: 'PORT',
        value: 'port',
      },
      {
        text: 'USERNAME',
        value: 'username',
      },

      {
        text: 'ENCRYPTION',
        value: 'encryption',
      },
      {
        text: '',
        value: 'actions',
      },
    ],
    icons: {
      mdiPencilOutline,
      mdiDeleteOutline,
      mdiPlus,
      mdiClose,
    },
    */
  }),
  created() {
    this.loadConfig().then((response) => {
      this.config = response.data
      //this.items_smtp = this.config.smtp
    })
  },
  methods: {
    ...mapActions('config', ['storeOrUpdate', 'loadConfig']),
    fileLogo(file) {
      this.file = file
    },
    fileLogoReport(file) {
      this.file_report = file
    },
    save() {

      if (!this.validate()) {
        return
      }

      this.loading = true

      let formData = new FormData()

      formData.append('name', this.config.name)
      formData.append('phone', this.config.phone)
      formData.append('address', this.config.address)
      formData.append('site', this.config.site)
      formData.append('email', this.config.email)
      formData.append('checkin', this.config.checkin)
      formData.append('checkout', this.config.checkout)
      formData.append('url_api', this.config.url_api)
      formData.append('api_key', this.config.api_key)
      formData.append('consumption_alert', this.config.consumption_alert ? 1 : 0)
      formData.append('consumption_max', this.config.consumption_max)
      formData.append('daily_alert_enabled', this.config.daily_alert_enabled ? 1 : 0)
      formData.append('daily_alert_time', this.config.daily_alert_time)
      formData.append('daily_alert_interval', this.config.daily_alert_interval)
      formData.append('whats_endpoint', this.config.whats_endpoint)
      formData.append('whats_key', this.config.whats_key)
      formData.append('whats_instance', this.config.whats_instance)

      if (this.file) {
        formData.append('logo', this.file)
      }
      if (this.file_report) {
        formData.append('logo_report', this.file_report)
      }

      this.storeOrUpdate(formData).then((response) => {
        this.$toast.success('Salvo com sucesso')
      }).finally(() => {
        this.loading = false
      })
    },

    validate() {
      if (this.config.consumption_alert == true && this.config.consumption_max == null) {
        this.consumption_max_input.message = 'Campo obrigatório'
        this.consumption_max_input.error = true
        return false
      }
      if (this.config.daily_alert_enabled == true && this.config.daily_alert_time == null) {
        this.daily_alert_input.message = 'Campo obrigatório'
        this.daily_alert_input.error = true
        return false
      }
      if (this.config.daily_alert_enabled == true) {
        if (this.config.daily_alert_interval == null) {
          this.daily_alert_interval_input.message = 'Campo obrigatório';
          this.daily_alert_interval_input.error = true;
          return false;
        }
        
        const interval = parseInt(this.config.daily_alert_interval, 10);
        
        if (!Number.isInteger(interval) || this.config.daily_alert_interval <= 0) {
          this.daily_alert_interval_input.message = 'O intervalo deve ser um número inteiro positivo';
          this.daily_alert_interval_input.error = true;
          return false;
        }
      }

      this.daily_alert_input.error = false
      this.daily_alert_input.message = null
      this.consumption_max_input.error = false
      this.consumption_max_input.message = null
      return true
    },
    /*
    addSmtp() {
      this.loading_smtp = true
      this.storeSmtp(this.smtp).then((response) => {
        this.items_smtp.push(Object.assign({}, response.data))

      }).finally((response) => {
        this.loading_smtp = false
      })

      this.smtp = Object.assign({}, this.smtp_default)
    },
    deleteSmtp(smtp) {
      this.loading_delete_smtp = true
      const index = this.items_smtp.indexOf(smtp)
      this.destroySmtp(smtp).then((response) => {
        this.items_smtp.splice(index, 1)
      }).finally(() => {
        this.loading_delete_smtp = false
      })
    }
  */

  }

}
</script>
