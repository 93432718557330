<template>
  <div>
    <v-data-table dense disable-pagination hide-default-footer :loading="loadingTable" :headers="headers"
      :items="checkouts" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Relatório Pagamentos</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon small outlined large class="ml-2" color="primary"
                @click="sendExportPdf()">
                <v-icon>{{ icons.mdiPrinter }}</v-icon>
              </v-btn>
            </template>
            <span>Imprimir</span>
          </v-tooltip>
          <v-badge :content="bagFilter" :value="bagFilter" bordered color="error" overlap>
            <v-btn @click="dialogFilter = true" class="ml-2" color="primary" outlined rounded>
              <v-icon>{{ icons.mdiFilter }}</v-icon>Filtros
            </v-btn>
          </v-badge>
          <v-spacer></v-spacer>
          <span>Periodo:<b style="color:black">{{ formatDate(filter.date_start) }}</b></span>
        </v-toolbar>
      </template>
      <template v-slot:item.checkout.accommodation.check_in="{ item }">
        {{ formatDate(item.checkout.accommodation.check_in) }}
      </template>
      <template v-slot:item.checkout.accommodation.check_out="{ item }">
        {{ formatDate(item.checkout.accommodation.check_out) }}
      </template> <template v-slot:item.guest_id_fiscal="{ item }">
        {{ getGuest(item) }}
      </template>
      <template class="py-2" v-slot:item.guests="{ item }">
        <v-container>
          <v-row v-for="(guest, index) in item.checkout.accommodation.guest" :key="index">
            - {{ guest.name }}
          </v-row>
        </v-container>
      </template>
      <template v-slot:item.payment="{ item }">
        <v-container>
          <v-row v-for="(payment, index) in item.payment_item" :key="index">
            {{ methodPayment(payment.method) }} - {{ formatMoney(payment.value) }}   
          </v-row>
        </v-container>
      </template>

      <template v-slot:foot>
        <tr style="height:44px; background:#fafafa">
          <td colspan="5" class="text-right" style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px">
            TOTAL:</td>
          <td class="text-center" style="border-top:solid 1px #e4e3e6 ;padding-left:16px;padding-right:16px">
            <b>{{ formatMoney(total()) }}</b>
          </td>
        </tr>
      </template>

      <template v-slot:no-data>
        <NoDataTable></NoDataTable>
      </template>
      <template v-slot:loading>
        Carregando...
      </template>

    </v-data-table>

    <!-- DIALOG FILTER -->
    <v-dialog v-model="dialogFilter" width="700px">
      <v-card>
        <BarTitleDialog title="Filtros" @close="dialogFilter = false" />
        <v-card-text>
          <v-row>
            <v-col>
              <v-card outlined>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-text-field v-model="filter.date_start" :error-messages="error.date_start"
                        @change="error.date = null" outlined label="Data inicial" type="date"></v-text-field>
                    </v-col>
                    <v-col>
                      <v-select outlined v-model="filter.payment_method" label="Método de Pagamento"
                        :items="payment_methods" item-text="text" item-value="value"></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col class="text-left">
              <v-btn text color="error" x-large @click="clearFilter()">limpar filtros</v-btn>
            </v-col>

            <v-col class="text-right">
              <v-btn text color="secondary" x-large @click="dialogFilter = false">FECHAR</v-btn>
              <v-btn :loading="loadingFilter" :disabled="loadingFilter" @click="confirmFilter()" color="primary"
                class="ml-2" x-large>aplicar</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <MsgDelete v-model="dialogDelete" @delete-yes="deleteItemConfirm()" />
  </div>
</template>

<script>
import MsgDelete from '@/components/MsgDelete.vue'
import NoDataTable from '@/components/NoDataTable.vue'
import { mdiClose, mdiTools, mdiFileExcelOutline, mdiFilter, mdiPrinter, mdiFilePdf, mdiFilePdfOutline } from '@mdi/js'
import { mapActions, mapGetters } from 'vuex'
import BarTitleDialog from '@/components/BarTitleDialog.vue'
import { formatDate } from '@/@core/utils/filter'


export default {
  components: {
    MsgDelete,
    NoDataTable,
    BarTitleDialog,
  },
  data: () => ({
    // DIALOG FILTER
    dialogFilter: false,
    filter: {
      payment_method: 'all',
    },
    loadingFilter: false,
    loadingTable: false,
    totalStagesPaginate: 0,
    numberOfPagesPaginate: 0,
    currentPagePaginate: 0,
    dialogDelete: false,
    dialog: false,
    loading: false,
    checkouts: [],
    payment_methods: [
      { value: 'all', text: 'Todos' },
      { value: 'debit_card', text: 'Cartão Débito' },
      { value: 'credit_card', text: 'Cartão Crédito' },
      { value: 'money', text: 'Dinheiro' },
      { value: 'other', text: 'Outros' },
      { value: 'pix', text: 'Pix' },
      { value: 'invoice', text: 'Faturado' },
      { value: 'b2b', text: 'B2B' },
    ],
    icons: {
      mdiClose,
      mdiTools,
      mdiFileExcelOutline,
      mdiFilter,
      mdiPrinter,
      mdiFilePdf,
      mdiFilePdfOutline,
    },
    headers: [
      {
        text: 'Reserva',
        align: 'start',
        value: 'checkout.accommodation.reserve_id',
      },
      {
        text: 'Checkin',
        align: 'start',
        value: 'checkout.accommodation.check_in',
        sortable: false,
      },
      {
        text: 'Checkout',
        align: 'center',
        value: 'checkout.accommodation.check_out',
        sortable: false,
      },
      {
        text: 'Empresa',
        align: 'center',
        value: 'checkout.accommodation.company.fantasy_name',
      },
      {
        text: 'Hóspede',
        align: 'center',
        value: 'guests',
      },
      {
        text: 'Pagamentos',
        align: 'center',
        value: 'payment',
        sortable: false,
      },
    ],
    error: {
      date_start: null,
      date_end: null,
    },
  }),

  computed: {
    bagFilter() {
      return Object.keys(this.filter).length
    },
  },

  watch: {

  },

  created() {

    this.periodInitial()
    this.initialize()
  },

  methods: {
    ...mapActions('payment_report', ['getAll', 'exportPdf']),

    initialize() {
      this.loadingTable = true
      this.getAll(this.filter)
        .then((response) => {

          this.checkouts = response.data
          this.loadingTable = false
        })
        .catch((error) => {
          this.loadingTable = false
          console.log(error)
        }).finally(() => {
          this.loadingTable = false
          this.loadingFilter = false
          this.dialogFilter = false
        })

    },
    periodInitial() {
      let dateToday = new Date();
      dateToday.setDate(dateToday.getDate() - 1);
      dateToday.setHours(dateToday.getHours() - 3);
      this.filter.date_start = dateToday.toISOString().split('T')[0]
    },
    confirmFilter() {
      //validar filtro periodo
      if (!this.filter.date_start) {
        this.error.date_start = 'Campo obrigatório'
        return false
      }
      this.loadingFilter = true
      this.initialize()
    },
    clearFilter() {
      this.filter = {}
    },
    formatMoney(value) {
      if (value != null) {
        return value.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      }
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year} `
    },

    sendExportPdf() {
      this.exportPdf(this.filter)
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'export.pdf')
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .finally()
    },
    formatDateTime(dateTime) {
      if (!dateTime) return null

      const [date, time] = dateTime.split(' ')
      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    },
    getGuest(item) {
      if (!item.guest_id_fiscal) return ''
      const guests = item.accommodation.guest
      const guest = guests.find(guest => guest.id == item.guest_id_fiscal)
      return guest.name
    },
    calcRecives(payment) {
      let total = 0
      payment.payment_item.forEach((item) => {
        total += parseFloat(item.value)
      })
      return total.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
    },
    total() {
      let total = 0
      this.checkouts.forEach((checkout) => {
        checkout.payment_item.forEach((item) => {
          total += parseFloat(item.value)
        })
      })
      return total
    },
    methodPayment(payment) {
      switch (payment) {
        case 'debit_card':
          return 'Cartão Débito'
        case 'credit_card':
          return 'Cartão Crédito'
        case 'money':
          return 'Dinheiro'
        case 'other':
          return 'Outros'
        case 'pix':
          return 'Pix'
        case 'invoice':
          return 'Faturado'
        case 'b2b':
          return 'B2B'
      }
      return
    },

  },
}
</script>
