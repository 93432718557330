<template>
  <div>
    <v-data-table :loading="loadingTable" :headers="headers" :items="audits" sort-by="calories" class="elevation-1"
      :page-count="numberOfPagesPaginate" :page="currentPagePaginate" :server-items-length="totalStagesPaginate"
      :options.sync="options" :footer-props="{
        itemsPerPageText: 'Itens por pagina',
        itemsPerPageOptions: [5, 10, 15],
        showFirstLastPage: true,
      }">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Auditorias</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn :disabled="already_audited" color="primary" dark rounded class="mb-2" v-bind="attrs" v-on="on">
                <v-icon small>
                  {{ icons.mdiPlus }}
                </v-icon>
                Auditar
              </v-btn>
            </template>
            <v-card>
              <div class="title_body">
                <span class="text_title">Confirmação de Auditoria - Fechamento do Dia</span>
                <span class="btn_close"><v-btn icon small @click="dialog = false"><v-icon dark> {{ icons.mdiClose }}
                    </v-icon></v-btn> </span>
              </div>

              <v-card-text>
                <v-container>
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <span class='text-body-1'>Você está prestes a confirmar a auditoria para o fechamento do dia.
                          Deseja
                          continuar?</span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDialog">
                  Fechar
                </v-btn>
                <v-btn :loading="loading" color="primary" @click="storeAudit">
                  Sim
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.date="{ item }">
        {{ formatDate(item.created_at) }}
      </template>

      <template v-slot:item.hour="{ item }">
        {{ formatTime(item.created_at) }}
      </template>

      <template v-slot:item.actions="{ item }">
        <div style=" white-space: nowrap;">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon class="" color="secondary" @click="exportRdsPdf(item)" v-bind="attrs" v-on="on">
                <v-icon>
                  {{ icons.mdiFileChartOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Rds</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">

              <v-btn icon class="" color="secondary" @click="exportDailyPdf(item)" v-bind="attrs" v-on="on">
                <v-icon>
                  {{ icons.mdiOfficeBuilding }}
                </v-icon>
              </v-btn>
            </template>
            <span>Lançamentos de Diárias</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon class="" color="secondary" @click="exportItensPdf(item)" v-bind="attrs" v-on="on">
                <v-icon>
                  {{ icons.mdiSilverwareVariant }}
                </v-icon>
              </v-btn>
            </template>
            <span>Lançamentos de Produtos/Serviços</span>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:no-data>
        <NoDataTable></NoDataTable>
      </template>
      <template v-slot:loading>
        Carregando...
      </template>
    </v-data-table>
  </div>
</template>

<script>

import { formatDate } from '@/@core/utils/filter';
import MsgDelete from '@/components/MsgDelete.vue'
import NoDataTable from '@/components/NoDataTable.vue'
import {
  mdiClose, mdiDeleteOutline, mdiPencilOutline,
  mdiPlus, mdiFileChartOutline, mdiOfficeBuilding,
  mdiSilverwareVariant

} from '@mdi/js'
import { mapActions } from 'vuex'

export default {
  components: {
    MsgDelete,
    NoDataTable,
  },
  data: () => ({
    itemsRoles: [],
    loadingTable: false,
    totalStagesPaginate: 0,
    numberOfPagesPaginate: 0,
    currentPagePaginate: 0,
    dialogDelete: false,
    dialog: false,
    loading: false,
    already_audited: false,
    headers: [
      {
        text: 'DATA',
        align: 'center',
        value: 'date',
      },
      {
        text: 'HORA',
        align: 'center',
        value: 'hour',
      },
      {
        text: 'USUÁRIO',
        align: 'center',
        value: 'user.name',
      },
      {
        text: 'AÇÃO',
        align: 'center',
        value: 'actions',
      },

    ],
    audits: [],
    options: {},
    icons: {
      mdiPencilOutline,
      mdiDeleteOutline,
      mdiPlus,
      mdiClose,
      mdiFileChartOutline,
      mdiOfficeBuilding,
      mdiSilverwareVariant,
    },
  }),

  computed: {

  },

  watch: {
    options: {
      handler() {
        this.initialize()
      },
    },
  },

  created() {
    this.initialize()

  },

  methods: {
    ...mapActions('audit', ['getAll', 'store']),
    ...mapActions('rds', {
      rdsPdf: 'exportPdf'
    }),
    ...mapActions('daily_report', {
      dailyExportPdf: 'exportPdf',
      itensExportPdf: 'exportPdfItensPdv'
    }),
    initialize() {
      this.loadingTable = true

      const { page, itemsPerPage } = this.options

      const payload = {
        itemsPerPage: itemsPerPage || 10,
        page,
      }

      this.getAll(payload)
        .then(response => {
          this.audits = response.data.data
          this.totalStagesPaginate = response.data.total
          this.numberOfPagesPaginate = response.data.last_page
          this.currentPagePaginate = response.data.current_page
          this.checkAudit()
        })
        .finally(() => {
          this.loadingTable = false

        })
    },

    formatDate(dateTime) {
      if (!dateTime) return null

      let date = new Date(dateTime);
      //date.setHours(date.getHours() - 3);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();

      return `${day}/${month}/${year}`
    },
    formatTime(dateTime) {
      if (!dateTime) return null

      let date = new Date(dateTime);
      const hour = date.getHours().toString().padStart(2, '0');
      const minute = date.getMinutes().toString().padStart(2, '0');

      return `${hour}:${minute}`
    },
    checkAudit() {
      let date = new Date();
      let last_audit = this.audits[0].created_at
      let last_audit_date = new Date(last_audit)
      if (formatDate(date) == formatDate(last_audit_date)) {
        this.already_audited = true
      }
    },
    closeDialog() {
      this.dialog = false
    },
    storeAudit() {
      this.loading = true
      this.store()
        .then(() => {
          this.dialog = false
          this.$toast.success('Auditoria realizada com sucesso')
        })
        .finally(() => {
          this.loading = false
          this.initialize()
        })
    },
    exportRdsPdf(item) {
      let date = new Date(item.created_at)
      date.setDate(date.getDate() - 1);
      let filter = {
        date: date.toISOString().substring(0, 10)
      }
      this.rdsPdf(filter)
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'export.pdf')
          document.body.appendChild(fileLink)
          fileLink.click()
        }).finally()
    },
    exportDailyPdf(item) {
      let date = new Date(item.created_at)
      date.setDate(date.getDate() - 1);
      let filter = {
        date_start: date.toISOString().substring(0, 10)
      }
      this.dailyExportPdf(filter)
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'export.pdf')
          document.body.appendChild(fileLink)
          fileLink.click()
        }).finally()
    },
    exportItensPdf(item) {
      let date = new Date(item.created_at)
      date.setDate(date.getDate() - 1);
      let filter = {
        date_start: date.toISOString().substring(0, 10)
      }
      this.itensExportPdf(filter)
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'export.pdf')
          document.body.appendChild(fileLink)
          fileLink.click()
        }).finally()
    },

  },
}
</script>
